:root {
    --poker-table-width: 90vw;
    --poker-table-height: 90vh;
    --poker-table-margin-top-bottom: calc(10vh / 2);
    --player-container-height: calc(
        (
                var(--poker-table-height) -
                    (2 * var(--poker-table-margin-top-bottom)) -
                    (5 * var(--default-padding))
            ) / 3
    );

    --blur-amount: 10px;

    --card-height: 80px;
    --card-width: 55px;
    --card-font-size: 28px;

    --default-padding: 10px;
}

.title {
    color: white;
    margin-top: 0;
    margin-bottom: var(--default-padding);
}

.title a {
    color: white;
}

.poker-table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*height: 100%;*/
    /*width: 100%;*/
    /*min-height: 90vh;*/
    min-width: 100%;
    max-height: var(--poker-table-height);
    margin-top: var(--default-padding);
    margin-bottom: var(--poker-table-margin-top-bottom);
}

.poker-table {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: var(--default-padding);
    /*padding: var(--default-padding);*/
    justify-content: center;
    align-items: center;
    border-radius: calc(var(--poker-table-height) / 2);
    width: var(--poker-table-width);
    height: var(--poker-table-height);
    max-height: 100vh;
    backdrop-filter: blur(var(--blur-amount));
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid black;
}

.table-center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-column: 3 / span 2; /* Span the middle two columns */
    grid-row: 2; /* Middle row */
    justify-self: center;
    align-self: center;
    background-color: var(--color-dark-grey);
    height: fit-content;
    /*padding: var(--default-padding);*/
    border-radius: calc(var(--card-width) / 2);
    border: 1px solid black;
}

.community-cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    height: fit-content;
    padding: 0 var(--default-padding) var(--default-padding)
        var(--default-padding);
}

.table-pot-420 {
    color: white;
    font-weight: bold;
    display: flex;
    height: var(--card-height);
    width: var(--card-height);
    border-radius: calc(var(--card-height) / 2);
    justify-content: center;
    align-items: center;
    position: relative;
}

.blinds {
    color: white;
    font-weight: normal;
    font-style: italic;
    display: flex;
    white-space: nowrap;
    border-radius: calc(var(--card-height) / 2);
    justify-content: center;
    align-items: center;
    position: relative;
    padding: var(--default-padding);
}

.card {
    width: var(--card-width); /* Adjust the size as needed */
    height: var(--card-height); /* Adjust the size as needed */
    margin: 0.5vh;
    background-color: #ffffff; /* TCard background */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Distributes children evenly */
    align-items: center;
    border-radius: calc(var(--card-width) / 8);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    font-family: 'Futura', sans-serif;
    position: relative;
    border: 1px solid black; /* TCard border */
    overflow: hidden; /* Ensures content does not overflow */
    padding: 3px 0; /* Vertical padding at the very top and bottom */
}

.card.hidden {
    /*    Make image fit fully*/
}

.card-value,
.card-suit {
    width: 100%; /* Full width */
    text-align: center;
    overflow: hidden; /* Ensures content does not overflow */
    white-space: nowrap; /* Prevents wrapping */
}

.card-value {
    height: calc(var(--card-height) / 2);
    font-size: var(--card-font-size);
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align to bottom of the top half */
}

.card-suit {
    height: 50%; /* Half the height of the card */
    font-size: var(--card-font-size); /* Adjust as needed */
    color: red; /* Default color, it changes for different suits */
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align to top of the bottom half */
}

.hearts,
.diamonds {
    color: red;
}

.clubs,
.spades {
    color: black;
}

.player-grid-box {
    min-height: var(--player-container-height);
    max-height: var(--player-container-height);
    height: 100%;
    padding: 0;
    display: flex;
    /*overflow-y: visible;*/
    /*align-self: flex-end;*/
}

.player-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: visible;
    align-self: flex-end;
}

.winner {
    border: 4px solid var(--color-green-light);
    border-radius: calc(var(--card-width) / 2);
    padding: var(--default-padding);
    backdrop-filter: blur(10px);
    background-color: var(--color-dark-grey);
    z-index: 100;
}

.thinking {
    border: 2px solid var(--color-yellow-light);
    border-radius: calc(var(--card-width) / 2);
    padding: var(--default-padding);
    backdrop-filter: blur(10px);
    background-color: var(--color-dark-grey);
    z-index: 100;
}

.human-player {
    border: 2px solid white;
    border-radius: calc(var(--card-width) / 2);
    backdrop-filter: blur(10px);
    background-color: var(--color-dark-grey);
    z-index: 100;
    padding: var(--default-padding);
}

.player {
    /*position: absolute;*/
    width: fit-content;
    min-width: calc(var(--card-width) * 3);
    /*height: calc(3 * var(--card-height));*/
    border-radius: calc(var(--card-width) / 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 30px; /* Vertically center the text */
    border: 1px solid #000;
    /*border-radius: 15px; !* Half of the player element height for rounded corners *!*/
    background-color: white;
    padding: calc(var(--default-padding) / 2);
    color: black;
}

.player-folded {
    background-color: var(--color-dark-grey);
    color: white;
}

.folded-text {
    font-style: italic;
    font-weight: bold;
}

.player-cards {
    display: flex;
    flex-direction: row;
    margin-top: calc(var(--default-padding) / 2);
}

.position-winnings-action-container {
    margin-bottom: var(--default-padding);
}

.position-or-winnings-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: var(--default-padding);
}

.player-winnings {
    display: flex;
    flex-direction: row;
    color: var(--color-green);
}

.player-position {
    display: flex;
    color: white;
    font-weight: bold;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 3px solid white;
    align-items: center;
    justify-content: center;
}

.player-position.dealer {
    background-color: white;
    color: var(--color-dark-grey);
}

.player-stack-and-action-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.player-action {
    width: fit-content;
    font-weight: bold;
    color: white;
}

.all_in {
    color: var(--color-red);
}

.check {
    color: white;
}

.call {
    color: var(--color-yellow);
}

.bet,
.raise {
    color: var(--color-green);
}

.player-stack {
    width: fit-content;
    color: white;
}

.player-info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.player-info-container-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.player-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid black;
    /*margin-bottom: calc(var(--default-padding) / 2);*/
    /*margin-top: calc(var(--default-padding) / 2);*/
}

.player-actions-container {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    margin-top: var(--default-padding);
}

.player-actions-bet-container {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    padding: var(--default-padding);
}

.action-button {
    /*margin-left: auto;*/
    height: fit-content;
    padding: 5px;
    background-color: var(--color-green);
    border-radius: 5px;
    cursor: pointer;
    font-size: calc(var(--font-size) * 0.85);
    font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
    font-weight: normal;
}

.fold-button {
    margin-top: var(--default-padding);
    background-color: var(--color-red);
    color: white;
}

.check-button {
    margin-top: var(--default-padding);
    background-color: var(--color-yellow);
}

.call-button {
    margin-top: var(--default-padding);
    background-color: var(--color-yellow);
}

.slider-container {
    position: relative;
    /*width: calc(var(--card-width) * 2);*/
    margin-top: calc(3 * var(--default-padding));
    margin-right: var(--default-padding);
}

.slider {
    /*width: 100%;*/
}

.slider-text {
    position: absolute;
    bottom: 100%;
    transform: translateX(-50%);
    white-space: nowrap;
    line-height: var(--font-size);
    background-color: white;
    border-radius: 5px;
}
